import { createContext, useEffect, useState } from "react";
import RESTCall from "./RESTCall";
import { redirect, getPart } from "./UrlFunctions";

const AuthContext = createContext(null);

export const AuthContextProvider = ({children}) => {
    const [session, setSession] = useState(null);
    const [user, setUser] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [loginFailed, setLoginFailed] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        RESTCall('auth/session', {}, 'GET', function(data){ userObjectResponse('session_check', data); });
    }, []);

    useEffect(() => {
        if (session) {
            getUser();
        } else {
            setUser(null);
        }
    }, [session]);

    async function getUser() {        
        setUser(session);
    }
    const updateUserCredits = (newCredits) => {
        setSession((prevSession) => ({
            ...prevSession,
            user: {
                ...prevSession.user,
                credits: newCredits
            }
        }))
    }

    async function verifyPaymentIntent(clientSecret, paymentIntentId) {
        const response = await RESTCall('payment/verify', {intent_id: paymentIntentId, client_secret: clientSecret}, 'POST');
        if(response.success) {
            setSession((prev) => ({...prev, user: {...prev.user, credits: response.result.credits}}));
        }
        return response;
    }

    async function getTransacitons() {
        const response = await RESTCall('payment/transaction', {}, 'GET');
        return response;
    }

    async function signUp(email, password, first_name, last_name) {
        const signUpData = await RESTCall('user', {email: email, password: password, first_name: first_name, last_name: last_name}, 'POST');
        return signUpData;
    }

    async function resetPassword(email) {
        const data = await RESTCall('auth/password/reset', {email: email}, 'POST');
        return data;
    }

    async function changePassword(password) {
        // const { data, error } = await supabase.auth.updateUser({
        //     password
        // });
        // if (error) {
        //     console.error(error);
        // }
    }

    async function signInWithEmail(loginInfo, password) {
        let result_valid = false;
        
        await RESTCall('auth/login', {email: loginInfo, password: password}, 'POST', function(data){ userObjectResponse('login', data); }).then(result => { 
            result_valid = result; 
        });

        return result_valid;
    };

    async function userObjectResponse(type, response) {
        
        if(response.success) {
            if(response.user === null || typeof response.user.id === 'undefined') {
                setSession(false);
                localStorage.removeItem("token");

                const PUBLIC_ROUTES = ["login", "forgot-password", "change-password", "register", "documentation"]
                const isPublicPage = PUBLIC_ROUTES.some( r => window.location.href.includes(r))
                // -- no need to redirect from public pages! --
                if (!isPublicPage) {
                    redirect('/login');
                    return;
                }
                return;
            }            

            const userData = { user: 
                {
                    id: response.user.id,
                    email: response.user.email,
                    auth_user_id: response.user.auth_user_id,
                    admin: response.user.admin,
                    moderator: response.user.moderator,
                    first_name: response.user.first_name,
                    last_name: response.user.last_name,
                    credits: response.user.credits                    
                }
            }
            localStorage.setItem("token", response.user.id);
            if(getPart(1) === 'login') redirect('/app/');

            setLoginFailed(false);
            setIsLoggedIn(true);
            setSession(userData);
        } else {
            if(type === 'login') {
                setLoginFailed(true);
                return false;
            }
            setIsLoggedIn(false);
            localStorage.removeItem("token", response.user.id);
            redirect('/login');
        }

        return true;
    }

    async function getPaymentIntent() {
        const paymentIntent = await RESTCall('payment/intent', {user: session.user.id, amount: 2000}, 'POST');
        return paymentIntent;
    }

    async function signOut() {
        await RESTCall('auth/logout', {}, 'GET', function(data){ userObjectResponse('logout', data); });
        setIsLoggedIn(false);
        setSession(false);
    };

    const value = {
      user,
      session,
      isLoggedIn,
      loginFailed,
      signInWithEmail,
      loading,
      setLoading,
      signOut,
      signUp,
      resetPassword,
      changePassword,
      getTransacitons,
      getPaymentIntent,
      updateUserCredits,
      verifyPaymentIntent,
    };

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;