import RESTCall from '../utils/RESTCall'

async function getLegislations() {
  const response = await RESTCall('lawyer/legislation', {}, 'GET');
  return response;
}

async function getUploadFormInfo(id) {
  const response = await RESTCall('form/'+id+'/info/upload', {}, 'GET');
  return response;
}

async function getFormFields(sessionId, callback) {
  const fields = await RESTCall(`form/field/${sessionId}`, {}, 'GET')
  callback(fields)
}

async function getForm(id) {
  if (id === 'new') return { success: false };
  const form = await RESTCall(`form/${id}`, {}, 'GET');
  return form;
}

async function deleteField(form_id, field_id) {
  const response = await RESTCall(`field/${form_id}/field`, { field: field_id }, 'DELETE')
  return response
}

async function updateForm( form_id, title, code, legislation, description, target) {
  const response = await RESTCall(`update_form/${form_id}`, {
      target: target,
      title: title ?? '',
      code: code ?? '',
      description: description ?? '',
      legislation: legislation ?? '',
    }, 'POST')
  return response
}

async function updateFormField(form_id, session_id, field_name, field_type, description, extras){
  const body = {
      form_id: form_id,
      session_id: session_id,
      field_type: field_type,
      field_name: field_name,
      description: description,
      extras: JSON.stringify(extras)
  };
  const response = await RESTCall('form/field_update', body, 'POST');
  return response;
}

async function sendFormAnswer(question, form_id, session_id){
  const response = await RESTCall('form/question/answer', {question: question, form_id: form_id, session_id: session_id}, "POST");
  if (response === null) {
    return { "success": false, "error": "No reply", "message": "Done." };
  }
  return response;
}

async function publishForm(form_id) {
  const response = await RESTCall(`form/${form_id}/publish`, {}, 'GET');
  return response;
}

async function uploadFormFile(file, callback) {
  const response = await RESTCall("form/upload", { file }, 'FILE');
  callback(response);
  return response;
}

async function sendFormQuestion(question, form_id, currentChat) {
  const response = await RESTCall('form/question', {session_id: currentChat, form_id: form_id, question: question}, 'POST');
  return response;
}

async function fetchForm(sessionId) {
  const form = await RESTCall(`form/${sessionId}`, {}, 'GET');
  return form;
}

async function fetchFormQuestions(){
  const forms = await RESTCall("form/question", {}, "GET");
  return forms;
}

async function getFormInfo(id) {
  const form = await RESTCall(`form/${id}/info`, {}, 'GET');
  return form;
}

async function getFormsList() {
  const forms = await RESTCall('form', {}, 'GET');
  return forms;
}

export {
  fetchForm,
  fetchFormQuestions,
  getLegislations,
  getUploadFormInfo,
  getForm,
  getFormFields,
  getFormInfo,
  getFormsList,
  updateForm,
  updateFormField,
  uploadFormFile,
  sendFormAnswer,
  publishForm,
  sendFormQuestion,
  deleteField,
};
