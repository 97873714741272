import RESTCall from '../utils/RESTCall'

async function getChats(id) {
  const chats = await RESTCall(`chat/who/${id}`, {}, 'GET')
  return chats
}

async function fetchChat(chatId) {
  const chat = await RESTCall(`chat/${chatId}`, {}, 'GET');
  return chat;
}

async function deleteChat(id) {
  const response = await RESTCall(`chat/${id}`, {}, 'DELETE');
  return response;
}

async function getUserChats(id) {
  const chats = await RESTCall(`user/chat/${id}`, {}, 'GET');
  return chats;
}

async function getCaseSummary(id) {
  const summary = await RESTCall(`lawyer/summarize/${id}`, {}, 'GET');
  return summary;
}


async function approvePost(messageId) {
  const response = await RESTCall(`post/${messageId}/approve`, {}, 'PATCH');
  return response;
}

async function flagPost(messageId) {
  const response = await RESTCall(`post/${messageId}/flag`, {}, 'PATCH');
  return response;
}

async function contactLawyer(propertyId, message) {
  const response = await RESTCall(`lawyer/contact/${propertyId}`, { message: message }, 'POST');
  return response;
}

async function getSampleQuestions() {
  const questions = await RESTCall('prompt/question/example', {}, 'GET');
  return questions;
}

async function sendQuestion(question, currentChat) {
  const response = await RESTCall('prompt/question', {question: question, session_id: currentChat}, 'POST');
  return response;
}

export {
  getChats,
  getSampleQuestions,
  fetchChat,
  deleteChat,
  getUserChats,
  getCaseSummary,
  approvePost,
  flagPost,
  contactLawyer,
  sendQuestion
};
