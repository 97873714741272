import React, { createContext, useState, useReducer, useEffect } from "react";
import { getPart } from "./UrlFunctions";
import { useLocation } from 'react-router-dom';
import {
  publishForm as publishFormAPI,
  fetchForm as fetchFormAPI,
  fetchFormQuestions as fetchFormQuestionsAPI
} from "../services/formAPI";
import {
  getChats as getChatsAPI,
  fetchChat as fetchChatAPI,
  getUserChats as getUserChatsAPI,
} from "../services/chatAPI";
import {
    getMemos as getMemosAPI,
    fetchMemo as fetchMemoAPI,
    getUserMemos as getUserMemosAPI
} from "../services/memoAPI";


const DATA_CONTEXT = {
    chats: [],
    current_chat: [],
    currentChatId: null,

    memos: [],
    current_memo: [],
    currentMemoId: null    
}

const dataReducer = (state, action) => {
    switch (action.type) {
        
        case 'SET_MEMOS':
            return { ...state, memos: action.payload };
        case "ADD_MEMO":
            const existsMemo = state.memos.filter(memo => memo.id === action.payload.id);
            if (existsMemo.length > 0) return state;
            return { ...state, memos: [action.payload, ...state.memos] };
        case "DELETE_MEMO":
            return { ...state, memos: state.memos.filter(memo => memo.id !== action.payload) };
        case "UPDATE_MEMO_FIELD":
            return { ...state, memos: state.memos.map(memo => memo.id === action.payload.id ? { ...memo, [action.payload.field]: action.payload.value } : memo) };
        case "UPDATE_CURRENT_MEMO_FIELD":
            return { ...state, current_memo: state.current_memo.map(message => message.id === action.payload.id ? { ...message, [action.payload.field]: action.payload.value } : message) };
        case "SET_CURRENT_MEMO":
            return { ...state, current_memo: action.payload };
        case "SET_CURRENT_MEMO_ID":
            return { ...state, currentMemoId: action.payload };
        case "ADD_MEMO_MESSAGE":
            return { ...state, current_memo: [...state.current_memo, action.payload] };

        case "SET_CHATS":
            return { ...state, chats: action.payload };
        case 'ADD_CHAT':
            const exists = state.chats.filter(chat => chat.id === action.payload.id);
            if (exists.length > 0) return state;
            return { ...state, chats: [action.payload, ...state.chats] };
        case 'INCREASE_CHAT_CREDITS':
            return { ...state, chats: state.chats.map(chat => chat.id === action.payload.id ? { ...chat, total_credits: chat.total_credits + action.payload.tokens } : chat) };
        case "UPDATE_CHAT_FIELD":
            return { ...state, chats: state.chats.map(chat => chat.id === action.payload.id ? { ...chat, [action.payload.field]: action.payload.value } : chat) };
        case 'UPDATE_CURRENT_CHAT_FIELD':
            return { ...state, current_chat: state.current_chat.map(message => message.id === action.payload.id ? { ...message, [action.payload.field]: action.payload.value } : message) };
        case "SET_CURRENT_CHAT":
            return { ...state, current_chat: action.payload };
        case "SET_CURRENT_CHAT_ID":
            return { ...state, currentChatId: action.payload };
        case "DELETE_CHAT":
            return { ...state, chats: state.chats.filter(chat => chat.id !== action.payload) };
        case "ADD_CHAT_MESSAGE":
            return { ...state, current_chat: [...state.current_chat, action.payload] };
        default:
            return state;
    }
}

const DataContext = createContext(DATA_CONTEXT);

export const DataContextProvider = ({ children }) => {
    // --- chat context ---
    const [data, dispatch] = useReducer(dataReducer, DATA_CONTEXT);

    // --- page context ---
    const [pageContext, setPageContext] = useState(null);
    const [contextId, setContextId] = useState(null);
    const [propertyId, setPropertyId] = useState(null);

    const location = useLocation();

    // --- sidebar context ---
    const [sidebar, setSidebar] = useState(false);

    const processContext = () => {
        switch(getPart(2)){
            case 'chat':
                setPageContext('chat');
                break;
            case '':
                setPageContext('home');
                break;
            case 'form':
            case 'forms':
                setPageContext('form');
                break;
            case 'upload_form':
            case 'form_question':
                setPageContext("form_question");
                break;
            case 'billing':
                setPageContext('billing');
                break;
            case 'user':
                setPageContext('user');
                break;
            case 'admin':
                setPageContext('admin');
                break;
        }

        
        if(getPart(3)){
            setContextId(getPart(3));
            setPropertyId(getPart(3));
            if(getPart(4)){
                setPropertyId(getPart(4));
            }
        } else {
            setContextId(null);
            setPropertyId(null);
        }
    }

    useEffect(() => {
        processContext();
    }, [location.pathname]);

    // --- chat context ---

    const addChatMessage = (payload) => {
        dispatch({ type: "ADD_CHAT_MESSAGE", payload: payload });
    }
    const addChat = (payload) => {
        dispatch({ type: "ADD_CHAT", payload: payload });
    }

    const updateCurrentChatField = (payload) => {
        dispatch({ type: "UPDATE_CURRENT_CHAT_FIELD", payload: payload});
    }

    const updateChatField = (payload) => {
        dispatch({ type: "UPDATE_CHAT_FIELD", payload: payload});
    }
    const deleteChat = (id) => {
        dispatch({ type: "DELETE_CHAT", payload: id})
    }
    const setCurrentChatID = (chatId) => {
        dispatch({ type: "SET_CURRENT_CHAT_ID", payload: chatId })
    }
    const setCurrentChatData = (payload) => {
        dispatch({ type: "SET_CURRENT_CHAT", payload: payload })
    }
    const setCurrentChatNew = () => {
        setCurrentChatID(null);
        setCurrentChatData([]);
    }
    const increaseChatCredits = (payload) => {
        dispatch({ type: "INCREASE_CHAT_CREDITS", payload: payload })
    }

    // --- memo context ---
    const addMemo = (payload) => {
        dispatch({ type: "ADD_MEMO", payload: payload });
    }
    const deleteMemo = (id) => {
        dispatch({ type: "DELETE_MEMO", payload: id });
    }
    const updateMemoField = (payload) => {
        dispatch({ type: "UPDATE_MEMO_FIELD", payload: payload });
    }
    const updateCurrentMemoField = (payload) => {
        dispatch({ type: "UPDATE_CURRENT_MEMO_FIELD", payload: payload });
    }
    const setCurrentMemoID = (memoId) => {
        dispatch({ type: "SET_CURRENT_MEMO_ID", payload: memoId });
    }
    const setCurrentMemoData = (payload) => {
        dispatch({ type: "SET_CURRENT_MEMO", payload: payload });
    }
    const addMemoMessage = (payload) => {
        dispatch({ type: "ADD_MEMO_MESSAGE", payload: payload });
    }
    const setCurrentMemoNew = () => {
        setCurrentMemoID(null);
        setCurrentMemoData([]);
    }

    const getUserChats = (id, callback) => {
        if(typeof id === 'undefined') return;
        getUserChatsAPI(id).then(result => {
            if (result.success) {
                callback(result.chats);
            }
        });
    }

    const getUserMemos = (id, callback) => {
        if(typeof id === 'undefined') return;
        getUserMemosAPI(id).then(result => {
            if (result.success) {
                callback(result.memos);
            }
        });
    }

    const getChats = (id) => {
        if(typeof id === 'undefined') {
            id = 'mine';
        }
        getChatsAPI(id).then(result => {;
            if (result.success) {
                dispatch({ type: "SET_CHATS", payload: result.chats });
            }
        });
    }

    const getMemos = (id) => {
        if(typeof id === 'undefined') {
            id = 'mine';
        }
        getMemosAPI(id).then(result => {
            if (result.success) {
                dispatch({ type: "SET_MEMOS", payload: result.memos });
            }
        });
    }

    const fetchMemo = (memoId, callback) => {
        fetchMemoAPI(memoId).then(result => {
            if (result.success) {
                setCurrentMemoData(result.chat);
                let found = data.memos.filter(memo => memo.id === memoId);
                if (found.length === 0) {
                    addMemo(result.info);
                } else {
                    updateMemoField({ id: memoId, field: 'title', value: result.info.title });
                }
            }
            if (callback) { callback(result.info); }
        });
    }

    const fetchChat = (chatId, callback) => {
        fetchChatAPI(chatId).then(result => {
            if (result?.success) {
                setCurrentChatData(result.chat);

                let found = data.chats.filter(chat => chat.id === chatId);
                if (found.length === 0) {
                    addChat(result.info);
                } else {
                    updateChatField({ id: chatId, field: 'total_credits', value: result.info.total_credits });
                    updateChatField({ id: chatId, field: 'title', value: result.info.title });
                }
            }
            if (callback) { callback(result.chat); }
        });
    }
    // -- this should be a separate service util
    const fetchFormQuestions = (callback) => {
        fetchFormQuestionsAPI().then(result => {
            if(result !== null && result?.success){
                let data = result.data;
                for(let i = 0; i < data.length; i++){
                    let cur = data[i];
                    cur.fields = cur.fields;
                    cur.questions = cur.questions; // Somehow a string is coming out as ``
                    cur.path=cur.title;
                    cur.name=cur.title;
                    cur.description=cur.title;
                    data[i] = cur;
                }
                callback(data);
            }
        });
    }

    const publishForm = (formId) => {
        publishFormAPI(formId).then(result => {
            if (result?.success) {
                updateChatField({ id: formId, field: 'published', value: true });
                
            }
        });
    }

    const fetchForm = (sessionId, callback) => {
        fetchFormAPI(sessionId).then(result => {
            if (result.success) {
                let found = 0;
                if (data.chats.length > 0) {
                    const filtered = data.chats.filter(chat => chat.id === sessionId);
                    found = filtered.length;
                }

                if (found === 0) {
                    addChat(result.info);
                } else {
                    updateChatField({ id: sessionId, field: 'title', value: result.info.form.title });
                }
                setCurrentChatData(result.chat);
            }
            if (callback) { callback(result); }
        });
    }

    const value = {
        chats: data.chats,
        memos: data.memos,
        current_chat: data.current_chat,
        current_memo: data.current_memo,
        currentChatId: data.currentChatId,
        pageContext,
        contextId,
        propertyId,
        addChatMessage,
        updateCurrentChatField,
        increaseChatCredits,
        getChats,
        getMemos,
        sidebar,
        setSidebar,
        setCurrentChatID,
        fetchChat,
        fetchForm,
        fetchFormQuestions,
        getUserChats,
        publishForm,
        deleteChat,
        setCurrentChatNew,
        setCurrentChatData,
        addChat,
        getUserMemos,
        updateChatField,
        addMemo,
        deleteMemo,
        fetchMemo,
        updateMemoField,
        updateCurrentMemoField,
        setCurrentMemoID,
        setCurrentMemoData,
        addMemoMessage,
        setCurrentMemoNew

    };

    return (
        <DataContext.Provider value={value}>
            { children }
        </DataContext.Provider>
    )
}


export default DataContext;