import RESTCall from '../utils/RESTCall'

async function getMemos(id) {
  const chats = await RESTCall(`memo/who/${id}`, {}, 'GET')
  return chats
}

async function fetchMemo(chatId) {
  const chat = await RESTCall(`memo/single/${chatId}`, {}, 'GET');
  return chat;
}

async function deleteMemo(id) {
  const response = await RESTCall(`memo/${id}`, {}, 'DELETE');
  return response;
}

async function getUserMemos() {
  const chats = await RESTCall(`memo/chat/mine`, {}, 'GET');
  return chats;
}

async function sendQuestion(question, currentChat) {
  const response = await RESTCall('memo/question', {question: question, session_id: currentChat}, 'POST');
  return response;
}

export {
    getMemos,
    fetchMemo,
    deleteMemo,
    getUserMemos,
    sendQuestion
};
